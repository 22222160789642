<template>
  <div>
    <div class="container">
      <div class="diannao">
        <div class="banner">
          <el-carousel>
            <el-carousel-item>
              <img src="../assets/banner1.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="../assets/banner2.jpg" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>

        <!-- <div class="bangzhu">
          <h3 class="title">视频教程</h3>
          <div class="items">
            <div class="item" v-for="item in videoList">
              <video controls :src="item.videoUrl" :poster="item.posterImg"></video>
            </div>
          </div>
          <div class="info">
            <div class="left">
              <h3>常见问题 <span>查看更多</span></h3>
              <ul>
                <li>
                  <span>1、如何保证产品的品质和可追溯性？</span>
                  <img src="../assets/view.png" alt="">
                  <div class="clear"></div>
                </li>
                <li>
                  <span>2、公司如何创新设计，吸引更多客户？</span>
                  <img src="../assets/view.png" alt="">
                  <div class="clear"></div>
                </li>
                <li>
                  <span>3、如何处理环境保护和可持续发展的问题？</span>
                  <img src="../assets/view.png" alt="">
                  <div class="clear"></div>
                </li>
                <li>
                  <span>4、如何应对仿冒品问题，维护自身品牌的声誉和利益？</span>
                  <img src="../assets/view.png" alt="">
                  <div class="clear"></div>
                </li>
                <li>
                  <span>5、如何利用数字化技术和电子商务提升服务水平和客户体验？</span>
                  <img src="../assets/view.png" alt="">
                  <div class="clear"></div>
                </li>



                <li>
                  <span>6、公司如何应对消费者对珠宝产品质量和可追溯性的日益关注？</span>
                  <img src="../assets/view.png" alt="">
                  <div class="clear"></div>
                </li>
              </ul>
            </div>
            <div class="right">
              <h3>热点问题 <span>查看更多</span></h3>
              <ul>
                <li>
                  <span>1、如何应对市场上的侵权问题？</span>
                  <img src="../assets/view.png" alt="">
                  <div class="clear"></div>
                </li>
                <li>
                  <span>2、公司如何应对市场竞争激烈的挑战？</span>
                  <img src="../assets/view.png" alt="">
                  <div class="clear"></div>
                </li>
                <li>
                  <span>3、公司如何确保产品的质量和安全可靠性？</span>
                  <img src="../assets/view.png" alt="">
                  <div class="clear"></div>
                </li>
                <li>
                  <span>4、公司如何应对日益增长的环保污染废弃压力？</span>
                  <img src="../assets/view.png" alt="">
                  <div class="clear"></div>
                </li>
                <li>
                  <span>5、公司在保证产品质量的同时如何降低对环境的影响？</span>
                  <img src="../assets/view.png" alt="">
                  <div class="clear"></div>
                </li>
                <li>
                  <span>6、公司如何利用数字化技术提升客户体验和品牌形象？</span>
                  <img src="../assets/view.png" alt="">
                  <div class="clear"></div>
                </li>

              </ul>
            </div>

          </div>
        </div> -->
        <div class="footer">
          袋鼠网页设计-成品源码站
        </div>
      </div>
      <div class="shouji">
        <div class="top">
          <h3>在线客服</h3>
          <img src="../assets/chahua.png" alt="">
          <h2>Hi~有什么可以帮您？</h2>
          <p>您的私人智能管家为您服务</p>
        </div>
        <div class="sort">
          <div class="item" @click="changActiveName('kefu')">
            <img src="../assets/j3.png" alt="">
            <p>在线客服</p>
          </div>
          <!-- <div class="item" @click="changActiveName('info')">
            <img src="../assets/j1.png" alt="">
            <p>常见问题</p>
          </div> -->
          <!-- <div class="item" @click="changActiveName('videos')">
            <img src="../assets/j2.png" alt="">
            <p>视频教程</p>
          </div> -->

        </div>
        <div class="tabs">
          <div class="info" v-if="activeName == 'info'">
            <h3>常见问题</h3>
            <div class="wenti">
              <div class="item" v-for="(item, index) in courseList" @click="viewDeatil(item.id)">
                <span class="s1">{{ index + 1 }}</span>
                <span class="s2">{{ item.title }}</span>
                <span class="s3">></span>
              </div>






              <!-- <div class="item">
                <span class="s1" style="color: #FF863B;">2</span>
                <span class="s2">下载压缩包文件之后如何解压缩？</span>
                <span class="s3">></span>
              </div>
              <div class="item">
                <span class="s1" style="color: #FFCD2D;">3</span>
                <span class="s2">如何打开查看网页html源码？</span>
                <span class="s3">></span>
              </div>
              <div class="item" style="border-bottom: none">
                <span class="s1" style="color: #7F5A83;">4</span>
                <span class="s2">如何在dw软件上使用这个模版？</span>
                <span class="s3">></span>
              </div>
              <div class="item" style="border-bottom: none">
                <span class="s1" style="color: #979797;">5</span>
                <span class="s2">下载的压缩包之后怎么用？</span>
                <span class="s3">></span>
              </div> -->
            </div>
          </div>
          <div class="kefu" v-if="activeName == 'kefu'">
            <h3>联系客服</h3>
            <img src="../assets/ma.png" alt="">
          </div>
          <div class="videos" v-if="activeName == 'videos'">
            <h3>视频教程</h3>
            <div class="items">
              <div class="item" v-for="item in videoList">
                <video :poster="item.posterImg" controls :src="item.videoUrl
                  "></video>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { post } from '@/util/request'
export default {
  components: {},
  data() {
    return {
      activeName: 'kefu',
      courseList: [],
      videoList: []
    }
  },
  methods: {
    viewDeatil(id) {
    
      this.$router.push({ name: 'wentiDetail', query: { id: id } })
    },
    changActiveName(val) {
      this.activeName = val
    },
    handleClick(tab, event) {
   
    },
    async getCourse() {
      const response = await post("/course/findAllList")
      this.courseList = response.data.data
     
    },
    async getVideo() {
      const response = await post("/video/findAllList")
      this.videoList = response.data.data
    
    }
  },
  created() {
    this.getCourse()
    this.getVideo()
  },
  mounted() {
   

  }
}
</script>
<style lang="less" scoped>
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

.diannao {
  display: block;

  .banner {
    width: 80%;
    height: 400px;
    margin: 0 auto;
    margin-top: 20px;

    ::v-deep .el-carousel__container {
      height: 400px;
    }
  }

  .test {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      flex-basis: 32%;
      background: red;
      height: 220px;
      margin-bottom: 30px;
    }
  }

  .bangzhu {
    width: 80%;
    margin: 0 auto;

    h3.title {
      margin: 20px 0px;
    }

    .items {
      display: flex;
      justify-content: space-between;

      flex-wrap: wrap;

      .item {
        flex-basis: 32%;
        height: 220px;
        margin-bottom: 30px;

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .info {
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;

      h3 {
        padding-bottom: 10px;
        border-bottom: 1px solid #B8B9B9;

        span {
          color: rgba(0, 0, 0, 0.6);
          float: right;
          font-size: 14px;
          display: block;
          margin-top: 8px;
        }
      }

      ul {
        list-style: none;
      }

      ul li {
        cursor: pointer;
        border-bottom: 1px solid #B8B9B9;
        height: 70px;
        line-height: 70px;

        span {
          float: left;
        }

        img {
          width: 30px;
          height: 30px;
          margin-top: 20px;
          float: right;
        }
      }

      .left {
        flex-basis: 46%;
      }

      .right {
        flex-basis: 46%;
      }
    }

  }

  .footer {
    width: 100%;
    height: 100px;
    line-height: 100px;
    background: #007AFF;
    color: white;
    text-align: center;
    font-size: 20px;
    letter-spacing: 3px;
    font-weight: bold;
  }

}

.shouji {
  display: none
}

/* 手机端样式 */
@media screen and (max-width: 768px) {


  .container {

    width: 100%;
    height: 90vh;
    background-image: url('../assets/beijing.png');
    background-size: cover;

    .diannao {
      display: none;
    }

    .shouji {
      display: block;

      .top {
        width: 100%;
        height: 25vh;
        position: relative;

        h2 {
          color: 050505;
          font-size: 15px;
          font-weight: 400;
          position: absolute;
          top: 80px;
          left: 25px;
          font-size: 20px;
          letter-spacing: 2px;

        }

        h3 {
          text-align: center;
          padding-top: 20px;
          color: #050505;
          font-size: 20px
        }

        p {
          position: absolute;
          top: 120px;
          left: 25px;
          color: #a6a6b0;
          font-size: 13px;
          font-weight: normal;
          letter-spacing: 2px;
        }

        img {
          width: 120px;
          height: 100px;
          position: absolute;
          top: 70px;
          right: 10px;
        }
      }

      .sort {
        width: 95%;
        margin: 0 auto;
        height: 15vh;
        background: white;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;

        .item {
          flex-basis: 100%;
          padding-top: 2.8vh;

          img {
            width: 38px;
            height: 38px;
            display: block;
            margin: 0 auto;

          }

          p {
            text-align: center;
            font-size: 12px;
            color: #050505;
            margin-top: 10px;
          }
        }
      }

      .tabs {
        width: 95%;
        margin: 0 auto;
        height: 46vh;
        background: white;
        margin-top: 20px;
        border-radius: 10px;
        padding: 15px;
        color: #050505;

        .videos {
          width: 100%;
          height: 100%;
          overflow-y: auto;

          .items {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 20px;

            .item {
              flex-basis: 48%;

              video {
                width: 100%;
                height: 120px;
                margin-bottom: 20px;
                object-fit: cover;
              }
            }
          }
        }

        .kefu {
          width: 100%;
          height: 100%;

          img {
            width: 80%;
            display: block;
            margin: 0 auto;
            height: auto;
            margin-top: 10%;
         
          }

        }

        .info {
          width: 100%;
          height: 100%;
          overflow-y: auto;

          .type {
            display: flex;
            justify-content: flex-start;
            margin-top: 20px;

            .item {
              flex-basis: 100px;
              height: 35px;
              border-radius: 5px;
              font-size: 13px;
              line-height: 35px;
              margin-right: 20px;
              text-align: center;
              background: #EFEFEF;
              color: black;
            }

            .active {
              background: #007aff;
              color: white;
            }
          }

          .wenti {
            .item {
              padding-bottom: 10px;
              border-bottom: 0.5px solid #ededed;
              margin-top: 20px;
              line-height: 30px;

              .s1 {
                display: inline-block;
                color: #D42323;
              }

              .s2 {
                display: inline-block;
                margin-left: 30px;
              }

              .s3 {
                float: right;
                color: #a6a6b0;
              }
            }
          }
        }
      }
    }




  }
}
</style>
