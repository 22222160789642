<template>

    <div class="parent_container">
        <div class="diannao">
            <div class="top">
                <div class="content">
                    <div class="left">
                        <img src="../assets/logo.png" alt="">
                    </div>
                    <div class="center">
                        <div class="item">
                            <router-link to="/index">首页</router-link>
                        </div>
                        <div class="item">
                            <router-link to="/jintai">模版中心</router-link>

                        </div>
                        <!-- <div class="item">
                            <router-link to="/jiaocheng">联系客服</router-link>
                        </div> -->
                        <div class="item" @click="showKefu">
                            在线客服
                        </div>
                    </div>
                    <div class="right">
                        <img src="../assets/shoucang.png" alt="">
                        <button @click="shoucang">收藏网址</button>
                    </div>
                </div>
            </div>

            <el-dialog title="在线客服" :visible.sync="dialogVisible" width="30%">
                <img src="../assets/ma.png" alt="">
            </el-dialog>
            <el-container v-if="!isMobile">
                <el-main>
                    <router-view />
                </el-main>
            </el-container>
        </div>
        <div class="shouji">
            <el-container v-if="isMobile">
                <el-main>
                    <router-view />
                </el-main>
                <div class="footer">
                    <router-link  active-class="active" to="/index">
                        <div class="item">
                            <img :src="$route.path == '/index' ? require('../assets/f1.png') : require('../assets/f1_1.png')"
                                alt="">
                            <h3 :style="{ color: $route.path === '/index' ? '#007aff' : '#a6a6b0', }">首页</h3>
                        </div>
                    </router-link>
                    <router-link  active-class="active" to="/jintai">
                        <div class="item">
                            <img :src="$route.path == '/jintai' ? require('../assets/f2.png') : require('../assets/f2_1.png')"
                                alt="">
                            <h3 :style="{ color: $route.path === '/jintai' ? '#007aff' : '#a6a6b0', }">模板库</h3>
                        </div>
                    </router-link>
                    <router-link  active-class="active" to="/jiaocheng">
                        <div class="item">
                            <img :src="$route.path === '/jiaocheng' ? require('../assets/f3.png') : require('../assets/f3_1.png')"
                                alt="">
                            <h3 :style="{ color: $route.path === '/jiaocheng' ? '#007aff' : '#a6a6b0', }">联系客服</h3>
                        </div>
                    </router-link> 
                </div>
            </el-container>
        </div>


    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            isMobile: false,
            dialogVisible: false,

        }
    },
    methods: {
        checkDeviceType() {
            this.isMobile = window.innerWidth <= 768; // 假设小于等于768px的是手机端
        },
        shoucang() {
            this.$alert('请按下 Ctrl + D 来添加书签！', '添加收藏', {
                confirmButtonText: '确定',

            });
            // this.$message({
            //     message: '请按下 Ctrl + D 来添加书签！',
            //     type: 'success'
            // });
        },
        showKefu() {
            this.dialogVisible = true
        }
    },

    created() {
        this.checkDeviceType();
        window.addEventListener('resize', this.checkDeviceType);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkDeviceType);
    }
}
</script>
<style lang="less" scoped>
.shouji {
    display: none;
}

// * {
//     padding: 0px;
//     margin: 0px;
//     box-sizing: border-box;
// }
::v-deep .el-main {
    padding: 0px !important;
}

.diannao {

    ::v-deep .el-dialog__body img {
        width: 100%;
        height: 100%;
    }

    .top {
        width: 100%;
        height: 80px;
        box-shadow: 0 0 5px #d2d2d2;

        a {
            text-decoration: none;
            color: black;
        }

        a:hover {
            color: #007AFF;
        }

        .content {
            width: 80%;
            height: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            .left {
                flex-basis: 15%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%
                }
            }

            .center {
                flex-basis: 70%;
                height: 100%;
                display: flex;

                .item {
                    flex-basis: 20%;
                    text-align: center;
                    line-height: 80px;
                    font-size: 18px;
                    font-family: '微软雅黑';
                    cursor: pointer;
                }

                .item:hover {
                    color: #007AFF;
                    font-size: 20px;
                }
            }

            .right {
                flex-basis: 15%;
                height: 100%;
                position: relative;

                img {
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    left: 15px;
                    top: 28px;
                }

                button {
                    background: #409EFF;
                    border: none;
                    height: 50px;
                    padding: 0px 20px;
                    padding-left: 50px;
                    border-radius: 10px;
                    color: white;
                    margin-top: 15px;
                    line-height: 50px;
                    font-size: 16px
                }
            }
        }
    }
}


@media screen and (max-width: 768px) {
    .diannao {
        display: none;
    }

    .shouji {
        display: block;


        * {
            box-sizing: border-box;
            margin: 0px;
            padding: 0px;
        }

        /* 路由内容区域 */
        .el-container {
            display: block;
        }

        /* 页脚 */
        .footer {
            width: 100%;
            height: 8vh;
            background: white;
            display: flex;
            justify-content: space-between;
            padding: 0px 40px;
            padding-top: 10px;

            a {
                text-decoration: none
            }

            .item {
                img {
                    width: 20px;
                    height: 20px;
                    display: block;
                    margin: 0 auto;
                }

                h3 {
                    font-size: 12px;
                    margin-top: 3px;
                    color: #a6a6b0;
                    font-weight: 400
                }
            }


        }
    }

}
</style>
