<template>
  <div class="container">
    <div class="diannao">
      <div class="search">
        <div class="keyword"> <input @focus="onSearchFocus" placeholder="请输入要搜索的关键词" type="text">
          <button>搜索</button>
        </div>
        <div class="hot">
          <span>热门搜索:</span>
          <span class="s2" @click="categoryClick2(item.id)" v-for="item in categoryList.slice(0, 9)" :key="item.id">{{
            item.name }}</span>

        </div>

      </div>
      <div class="banner">
        <img src="../assets/banner7.png" alt="">
        <!-- <el-carousel>
          <el-carousel-item>
            <img src="../assets/banner4.png" alt="">
          </el-carousel-item>
          
        </el-carousel> -->
      </div>
      <div class="zhuti">
        <h3 class="title">热门主题 <span @click="viewMore">查看更多</span></h3>
        <div class="items">
          <div class="item" @click="categoryClick2(item.id)" :style="{ backgroundImage: `url(${item.coverImg})` }"
            v-for="(item, index) in categoryList.slice(0, 8)" :key="index">
            <div class="mask">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="like">
        <div class="title">
          <h4>猜你喜欢</h4>
          <div class="more" @click="viewMore">
            <span>查看更多</span>
            <img src="../assets/more.png" alt="">
          </div>

        </div>

        <div class="items">

          <div class="item" @click="viewDetail2(item.id)" v-for="item in mobanList['diannaoList']">
            <img class="cover" :src="item.coverImg" alt="">
            <div class="mobaninfo">
              <h3 class="title2">{{ item.title }}/{{ item.categoryName }}主题</h3>
              <div class="skill">
                <img src="../assets/biaoshi.png" alt="">
                <span>编号: {{ item.bizNo }}</span>
                <span>页数: {{ item.pageNumber }}</span>
              </div>
              <div class="skill">
                <img src="../assets/biaoshi.png" alt="">
                <span>技术:{{ item.skillLableName }} </span>
              </div>
              <div class="price">
                <span class="s1">¥</span><span class="s2">{{ item.price }}</span>
                <button>查看</button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="footer">
        袋鼠网页设计-成品源码站
      </div>
    </div>
    <div class="shouji">

      <div class="search">
        <el-input placeholder="搜索精品模版" @focus="searchFocus">
          <el-button slot="append" type="primary" icon="el-icon-search">搜索</el-button>
        </el-input>
      </div>
      <div class="banner">
        <el-carousel>
          <el-carousel-item>
            <img src="../assets/banner7.png" alt="">
          </el-carousel-item>

        </el-carousel>
      </div>

      <div class="content">
        <h3 class="title" style="margin-top: 20px;">热门主题 <span id="moreMoban" @click="goMobanCenter">查看更多 ></span></h3>

        <div class="category">
          <div class="sort1">
            <div @click="categoryClick(item.id)" class="item" v-for="(item, index) in this.categoryList.slice(0, 5)">
              <div class="bg" :style="{ background: colorList[index] }">
                <img :src="require(`@/assets/t${index + 1}.png`)" alt="">
              </div>
              <h3>{{ item.name }}</h3>
            </div>
          </div>
          <div class="sort2">
            <div @click="categoryClick(item.id)" :style="{ backgroundImage: `url(${item.coverImg})` }"
              v-for="(item, index) in this.categoryList.slice(5, 9)" class="item">
              <div class="mask">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <h3 class="title" style="margin-top: 20px;">猜你喜欢 <span @click="goMobanCenter">查看更多 ></span></h3>
        <div class="mobans">
          <div class="item" v-for="item in mobanList['shoujiList']" @click="viewDetail(item.id)">
            <img class="cover" :src="item.coverImg" alt="" loading="lazy">
            <div class="detail">
              <h3 class="title2">{{ item.title }}/{{ item.categoryName }}主题</h3>
              <div class="skill">
                <img src="../assets/biaoshi.png" alt="">
                <span>编号: {{ item.bizNo }}</span>
                <span>页数: {{ item.pageNumber }}</span>
              </div>
              <div class="skill">
                <img src="../assets/biaoshi.png" alt="">
                <span>技术:{{ item.skillLableName }} </span>
              </div>
              <div class="price">
                <span class="s1">¥</span><span class="s2">{{ item.price }}</span>
                <button>查看</button>
              </div>

            </div>
          </div>
        </div>
        <v-tour name="myTour" :steps="steps" :options="myOptions" :callbacks="myCallbacks"></v-tour>
      </div>
      <div class="footer">
        专业网页-设计制作
      </div>
    </div>

  </div>
</template>

<script>
import { getCategoryList, getIndexList, getRandomSubset } from "@/util/api";
import vueWaterfallEasy from 'vue-waterfall-easy'
export default {
  components: {
    vueWaterfallEasy
  },
  data() {
    return {
      colorList: ['#3C82F5', '#FD5232', '#FDD03F', '#42E2F8', '#A943FC'],
      categoryList: [],
      mobanList: [],
      steps: [
        {
          target: '#moreMoban',  // We're using document.querySelector() under the hood
          header: {
            title: '点击查看更多',
          },
          content: `可以查看更多模板`,
          // params: {
          //     placement: 'bottom-start' 
          //   }
        }

      ],
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: '跳过',
          buttonPrevious: '上一步',
          buttonNext: '下一步',
          buttonStop: '了解'
        }
      },

      myCallbacks: {

        onStop: this.myCustomStopCallback
      },
      isIndexTour: true

    }
  },
  methods: {
    myCustomStopCallback() {
      localStorage.setItem("indexTour", false)

    },

    onSearchFocus() {
      this.$router.push('/jintai')
    },
    viewMore() {
      this.$router.push('/jintai')
    },
    categoryClick(categoryId) {
  
      this.$router.push({ name: 'jintai', query: { categoryId: categoryId } })
    },
    categoryClick2(categoryId) {
    
      this.$router.push({ name: 'jintai', query: { categoryId: categoryId } })
    },

    viewDetail(id) {
 
      this.$router.push({ name: 'jintaidetail', query: { id: id } })
    },
    viewDetail2(id) {
      this.$router.push({ name: 'jintaidetail', query: { id: id } })
    },
    searchFocus() {
      this.$router.push('/search')
    },
    goMobanCenter() {
      this.$router.push('/jintai')
    }

  },
  async created() {
    this.categoryList = await getCategoryList()
    this.mobanList = await getIndexList()
  },
  mounted: function () {

    let flag = localStorage.getItem("indexTour")

    if (flag != null) {
      this.isIndexTour = flag === "true"
    }
    if (this.isIndexTour) {
   
      this.$tours['myTour'].start()
    }


  }
}
</script>
<style lang="less" scoped>
.shouji {
  display: none;
}


.diannao {


  * {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
  }

  h3.title {
    margin: 20px 0px;

    span {
      float: right;
      color: #999;
      font-weight: normal;
      font-size: 16px;
      margin-top: 2px;
      cursor: pointer;
    }
  }

  .search {
    width: 900px;
    height: 90px;

    margin: 30px auto;

    .keyword {
      display: flex;

      input {
        flex-basis: 790px;
        display: block;
        height: 60px;
        float: left;
        border: 2px solid #007aff;
        outline: none;
        /* 去掉聚焦时的轮廓 */
        padding-left: 15px;
      }

      input::placeholder {
        font-size: 16px;
        padding-left: 15px;
        letter-spacing: 3px;
      }

      button {
        display: inline-block;
        flex-basis: 110px;
        height: 60px;
        background: #007AFF;
        border: none;
        color: white;
        text-align: center;
        line-height: 60px;
        font-size: 16px;
        cursor: pointer;
      }
    }

    .hot {
      margin-top: 15px;
      margin-bottom: 10px;


      span {
        flex-basis: 60px;
        font-size: 14px;
        margin-right: 20px;
        color: #999;
      }

      span.s2 {
        cursor: pointer;

        &:hover {
          font-weight: bold;
          color: #007AFF;
        }
      }


    }

  }

  .banner {
    width: 85%;
    height: 450px;
    margin: 0 auto;

    ::v-deep .el-carousel__container {
      height: 400px;
    }

    img {
      width: 100%;
      height: 100%;

    }
  }

  .zhuti {
    width: 85%;
    margin: 0 auto;

    .items {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 20px;

      .item {
        flex-basis: 23.5%;
        margin-top: 10px;
        height: 200px;
        background-size: cover;
        position: relative;
        cursor: pointer;

        .mask {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0px;
          left: 0px;
          background: rgba(0, 0, 0, .5);
          z-index: 999;
          text-align: center;
          line-height: 200px;
          color: white;
          font-weight: bold;
          font-size: 18px;
          letter-spacing: 3px;
        }
      }


    }


  }

  .like {
    width: 85%;
    margin: 0 auto;

    .title {
      width: 100%;
      height: 50px;
      background: #007AFF;
      margin: 20px 0px;
      border-radius: 10px;
      position: relative;



      h4 {
        color: white;
        text-align: center;
        line-height: 50px;
      }

      .more {
        position: absolute;
        right: 20px;
        top: 10px;
        cursor: pointer;

        span {
          display: block;
          width: 100px;
          position: absolute;
          right: -12px;
          top: 5px;
          color: white;
          font-size: 15px;
        }

        img {
          position: absolute;
          width: 25px;
          height: 25px;
          right: 0px;
          top: 3px;
        }
      }
    }






    .items {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 30px;

      .item {
        flex: 0 0 calc(20% - 30px);
        margin-bottom: 30px;
        box-shadow: 0 .125rem .3rem -.0625rem rgba(0, 0, 0, .03), 0 .275rem .75rem -.0625rem rgba(0, 0, 0, .06) !important;
        border-radius: 10px;

        // h3.title2 {
        //   margin: 10px 0px;
        //   text-align: center;
        // }

        img.cover {
          width: 100%;
          height: 300px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        .mobaninfo {
          padding-bottom: 15px;
          padding-top: 10px;
          background: white;
          padding-left: 10px;
          padding-top: 10px;
          padding-bottom: 10px;
          background: white;

          h3.title2 {
            font-size: 15px;
            font-weight: bold;
          }

          .skill {
            margin-top: 5px;

            img {
              width: 10px;
              height: 10px;
            }

            span {
              font-size: 14px;
              vertical-align: middle;
              display: inline-block;
              margin-left: 5px;

            }
          }


          .price {
            color: #E63946;
            padding-right: 5px;
            margin-top: 5px;

            .s1 {
              font-size: 13px;
              font-weight: bold;
              margin-right: 3px;
            }

            .s2 {
              font-size: 18px;
              font-weight: bold;
            }

            button {
              background: #D81E06;
              height: 20px;
              width: 60px;
              text-align: center;
              color: white;
              float: right;
              margin-top: 5px;
              border-radius: 10px;
              border: none;
              font-weight: 500;
            }

          }



        }
      }
    }
  }

  .footer {
    width: 100%;
    height: 100px;
    line-height: 100px;
    background: #007AFF;
    color: white;
    text-align: center;
    font-size: 20px;
    letter-spacing: 3px;
    font-weight: bold;
  }
}

::v-deep .vue-tour__popover {
  margin-right: 20px;
  /* 增加右侧外边距，控制与屏幕右边的间隙 */
}

::v-deep .v-step__header {
  background: #3A86FF !important;
}

::v-deep .v-step {
  background: #3A86FF !important;
  font-size: 14px;
  inset: 0px auto auto -15px !important;
}

::v-deep .v-step__arrow::before {
  background: #3A86FF !important;
}


/* 手机端样式 */
@media screen and (max-width: 768px) {
  .shouji {
    display: block
  }



  .diannao {
    display: none
  }

  * {
    margin: 0px;
    box-sizing: border-box
  }

  /* 搜索框 */
  .search {
    width: 98%;
    margin: 10px auto;
  }

  ::v-deep .el-input__inner {
    padding: 25px;
    border: 1.5px solid #007aff;
    font-size: 18px !important;

  }

  .el-button--primary {
    color: #FFF !important;
    background-color: #007aff !important;
    border-color: #007aff !important;
    border-radius: 0px !important;
    height: 53px;

  }

  /* h3标题 */
  h3.title {
    padding-left: 20px;
    font-size: 15px;
    padding-right: 20px;

    span {
      float: right;
      color: #a6a6b0;
      font-weight: normal;
      font-size: 14px;
    }
  }


  /* banner样式 */
  .banner {
    width: 100%;
    margin: 0px;
    height: 160px;

    img {
      width: 100%;
      height: 100%;

    }
  }

  ::v-deep .el-carousel__container {
    height: 160px;
  }

  /* 热门主题样式 */
  .category {
    .sort1 {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      padding: 0px 20px;
    }

    .sort1 .item {
      flex-basis: 50px;
    }

    .sort1 .item .bg {
      border-radius: 10px;
      width: 100%;
      height: 50px;
    }

    .sort1 .item .bg img {
      width: 30px;
      height: 40px;
      display: block;
      margin: 0 auto;
      padding-top: 10px
    }

    .sort1 .item h3 {
      font-size: 12px;
      text-align: center;
      margin: 10px 0px;
    }

    .sort2 {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0px 20px;

    }

    .sort2 .item {
      flex-basis: 47%;
      margin-top: 10px;
      height: 100px;

      background-size: cover;
      position: relative;
    }



    .sort2 .item .mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      background: rgba(0, 0, 0, .4);
      z-index: 999;
      text-align: center;
      line-height: 100px;
      color: white;
      font-weight: bold;
    }
  }

  /* 模版列表 */
  .mobans {
    display: flex;
    padding: 0px 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;

    .item {
      flex-basis: 48%;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
      margin-bottom: 20px;

      img.cover {
        width: 100%;
        height: 210px
      }

      .detail {
        padding-bottom: 15px;
        padding-top: 10px;
        background: white;

        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        background: white;

        h3.title2 {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.8);

          font-weight: bold;

        }

        .skill {
          margin-top: 5px;

          img {
            width: 10px;
            height: 10px;
          }

          span {
            font-size: 12px;
            vertical-align: middle;
            display: inline-block;
            margin-left: 5px;

          }
        }


        .price {
          color: #E63946;
          padding-right: 5px;
          margin-top: 5px;

          .s1 {
            font-size: 13px;
            font-weight: bold;
            margin-right: 3px;
          }

          .s2 {
            font-size: 18px;
            font-weight: bold;
          }

          button {
            background: #D81E06;
            height: 20px;
            width: 60px;
            text-align: center;
            color: white;
            float: right;
            margin-top: 5px;
            border-radius: 10px;
            border: none;
            font-weight: 500;
          }

        }



      }
    }
  }

  /* 页脚 */
  .footer {
    width: 100%;
    height: 60px;
    font-size: 16px;
    line-height: 60px;
    background: #007aff;
    text-align: center;
    color: white;
    letter-spacing: 5px;
    margin-bottom: 0px;
    font-weight: bold;
  }

}
</style>
