<template>
    <div class="container">
        <div class="diannao">
            <img src="../assets/renshu.png" alt="">
            <h3>当前访问人数太多，请稍后访问</h3>
        </div>
        <div class="shouji">
            <img src="../assets/renshu.png" alt="">
            <h3>当前访问人数太多，请稍后访问</h3>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {

        }
    },
    methods: {},
    created() { }
}
</script>
<style lang="less" scoped>
.shouji {
    display: none;
}

.diannao {
    display: block;
}

.diannao img {
    width: 60%;
    display: block;
    margin: 0 auto;
    margin-top: 10%;
}

.diannao h3 {
    text-align: center;
}

@media screen and (max-width: 768px) {
    .diannao {
        display: none;
    }

    .shouji {
        display: block;
        height: 52vh;
    }

    .shouji img {
        width: 60%;
        display: block;
        margin: 0 auto;
        margin-top: 80%;
    }

    .shouji h3 {
        text-align: center;
    }
}
</style>
