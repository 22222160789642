<template>
    <div class="container">

        <div class="diannao">
            <div class="breadcrumb">
                <a href="">首页 > </a> <a href="" class="second">模版中心</a>
            </div>
            <h3 class="title">成品展示</h3>
            <div class="moban">
                <div class="left">
                    <img :src="mobanDetail.coverImg" alt="">
                </div>
                <div class="right">
                    <h3>{{ mobanDetail.title }}</h3>
                    <div class="price">
                        <span class="s1">价格:</span>
                        <span class="s2">￥{{ mobanDetail.price }}</span>
                    </div>
                    <div class="info">
                        <div class="item">
                            <span class="s1">所属分类:</span>
                            <span class="s2">{{ mobanDetail.categoryName }}</span>
                        </div>
                        <div class="item">
                            <span class="s1">页数:</span>
                            <span class="s2">{{ mobanDetail.pageNumber }}页</span>
                        </div>


                    </div>
                    <div class="info">
                        <div class="item">
                            <span class="s1">模版编号:</span>
                            <span class="s2">{{ mobanDetail.bizNo }}</span>
                        </div>
                        <div class="item">
                            <span class="s1">布局:</span>
                            <span class="s2">{{ mobanDetail.skill }}</span>
                        </div>


                    </div>
                    <div style="margin-bottom: 0px;" class="info">

                        <div class="item">
                            <span class="s1">文件大小:</span>
                            <span class="s2">30M</span>
                        </div>
                        <div class="item">
                            <span class="s1">技术:</span>
                            <span class="s2">{{ mobanDetail.skill }}</span>
                        </div>
                    </div>
                    <div class="btns">
                        <button @click="showVideo">视频展示</button>
                        <button @click="buy" style="background: #E63946;">立即购买</button>
                    </div>

                </div>
            </div>

            <el-dialog class="customDialog" :visible.sync="videoDialog" width="50%" title="成品视频展示"
                @close="handleVideoClose">
                <video ref="videoPlayer" controls poster="../assets/video.png" :src="mobanDetail.videoUrl"></video>
            </el-dialog>

            <h3 class="title">成品图片</h3>
            <div class="picture">
                <!-- <div class="imgs" >
                    <img v-for="src in imgList" :src="src" alt="">
                </div> -->
                <div class="carousel-container">
                    <div class="carousel" :style="carouselStyle">
                        <div @click="openDialog(index)" class="carousel-item" v-for="(image, index) in visibleImages"
                            :key="index">
                            <img :src="image" alt="Image" />
                            <span>第{{ index + 1 }}页</span>
                        </div>
                    </div>
                    <button v-if="this.imgList.length > 5" @click="prev" class="nav-button prev-button">&lt;</button>
                    <button v-if="this.imgList.length > 5" @click="next" class="nav-button next-button">&gt;</button>
                </div>

                <el-dialog :visible.sync="viewPictureDialog" width="50%">
                    <el-carousel :initial-index="currentIndex" indicator-position="none" :autoplay="false"
                        arrow="always" ref="carousel" height="500px" @change="handleChange">
                        <el-carousel-item v-for="(image, index) in imgList" :key="index">
                            <h3>第{{ index + 1 }} 页</h3>
                            <img :src="image" alt="Preview Image"
                                style="width: 100%; height: 100%; object-fit: contain;" />
                        </el-carousel-item>
                    </el-carousel>
                    <!-- <div slot="footer" class="dialog-footer">
                        <el-button @click="prevImage">上一张</el-button>
                        <el-button @click="nextImage">下一张</el-button>
                    </div> -->
                </el-dialog>

            </div>
            <h3 class="title">买前须知</h3>
            <div class="banquan">
                <p>1 本模版已申请了作品版权，购买后只有使用权，没有售卖权，禁止将本作品二次售卖、二次修改、抄袭、传播等产生商业利益，否则一定依法维权处理！！！</p>
                <p style="margin: 10px 0px;">
                    2 成品源码同一个学校同一份只卖一次，买之前会查重避免撞车，请大家放心

                </p>
                <p>
                    3 成品不提供技术指导和免费修改服务的哈，不支持退换货的哦，拍前请选好自己心仪的网页成品！(需要付费修改的请咨询我们客服)
                </p>

            </div>
            <h3 class="title">相关推荐</h3>
            <div class="other">
                <div class="item" @click="viewDetail(item.id)" v-for="item in relatedList">
                    <img :src="item.coverImg" alt="">
                    <h3>{{ item.title }}</h3>
                </div>

            </div>



            <div class="footer">
                袋鼠网页设计-成品源码站
            </div>
        </div>

        <div class="shouji">
            <div class="top">
                <img v-if="canGoBack" @click="back" class="back" src="../assets/back.png" alt="">
                <h2>{{ mobanDetail.title }}</h2>
            </div>
            <div class="lunbo">
                <el-carousel :interval="4000" type="card" height="32vh" arrow="always">
                    <el-carousel-item v-for="(imgsrc, index) in imgList" :key="index">
                        <img :src="imgsrc" @click="showImage(index)" style="cursor: pointer;" alt="">
                    </el-carousel-item>
                </el-carousel>
                <el-dialog :visible.sync="dialogVisible" width="85%" custom-class="large-dialog">
                    <el-carousel @change="carouselChange" :initial-index="currentIndex" ref="fullsizeCarousel"
                        arrow="always" indicator-position="none">
                        <el-carousel-item :autoplay="false" v-for="(item, index) in imgList" :key="index">
                            <img :src="item" alt="Image" style="width: 100%;" />
                        </el-carousel-item>
                    </el-carousel>
                    <div class="pageNumber">第{{ currentIndex + 1 }}页</div>
                </el-dialog>
            </div>
            <div class="jieshao">
                <h3 class="title">模版说明</h3>
                <div class="items">
                    <div class="item">
                        <span class="s1">编号 :</span>
                        <span class="s2">{{ mobanDetail.bizNo }}</span>

                    </div>
                    <div class="item">
                        <span class="s1">名称 :</span>
                        <span class="s2">{{ mobanDetail.title }}</span>

                    </div>
                    <div class="item">
                        <span class="s1">价格 :</span>
                        <span class="s2">{{ mobanDetail.price }}</span>
                        <span></span>
                    </div>
                    <div class="item">
                        <span class="s1">页数 :</span>
                        <span class="s2">{{ mobanDetail.pageNumber }}页</span>
                        <span></span>
                    </div>
                    <div class="item">
                        <span class="s1">分类 :</span>
                        <span class="s2">{{ mobanDetail.categoryName }}</span>
                        <span></span>
                    </div>
                    <div class="item">
                        <span class="s1">文件大小 :</span>
                        <span class="s2">{{ mobanDetail.fileSize }}mb</span>
                    </div>
                    <div class="item item2">
                        <span class="s1">技术标签 :</span>
                        
                        <el-tag v-for="(tag, index) in skillLableTags" :key="index">
                            {{ tag.text }}
                        </el-tag>
                    </div>
                </div>
            </div>
            <div class="shipin">
                <h3 class="title">视频展示</h3>
                <video post controls :src="mobanDetail.videoUrl" poster="../assets/video.png"></video>
            </div>

            <div class="shuoming">
                <h3 class="title">买前须知</h3>
                <p>1 本模版已申请了作品版权，购买后只有使用权，没有售卖权，禁止将本作品二次售卖、二次修改、抄袭、传播等产生商业利益，否则一定依法维权处理！！！</p>
                <p style="margin: 10px 0px;">
                    2 成品源码同一个学校同一份只卖一次，买之前会查重避免撞车，请大家放心

                </p>
                <p>
                    3 成品不提供技术指导和免费修改服务的哈，不支持退换货的哦，拍前请选好自己心仪的网页成品！(需要付费修改的请咨询我们客服)
                </p>
            </div>

        </div>

    </div>
</template>

<script>
import { post } from "@/util/request";
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'
export default {


    components: {

    },
    data() {
        return {
            id: null,
            canGoBack: false,
            images: [
                'https://via.placeholder.com/300?text=1',
                'https://via.placeholder.com/300?text=2',
                'https://via.placeholder.com/300?text=3',
                'https://via.placeholder.com/300?text=4',
                'https://via.placeholder.com/300?text=5',
                'https://via.placeholder.com/300?text=6', // 多于5张的图片
            ],
            tagTypes: ['success', 'success', 'success', 'success'], // 所有可能的 type 值
            nowIndex: 0,
            videoDialog: false,
            viewPictureDialog: false,
            transitioning: false,
            dialogVisible: false,
            currentIndex: 0,
            mobanDetail: {},
            imgList: [],
            relatedList: [],
            skillLableList: []

        };
    },
    methods: {
        handleChange(index) {
        },
        back() {
            this.$router.go(-1)
        },
        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        },
        viewDetail(id) {
            this.id = id
            this.getDetail()
        },
        handleVideoClose() {
            const video = this.$refs.videoPlayer;
            // 停止视频播放并重置播放位置
            video.pause();
            video.currentTime = 0;
        },
        showVideo() {
            this.videoDialog = true

        },
        buy() {
            alert("联系客服购买")
        },
        openDialog(index) {
            this.nowIndex = index;
            this.viewPictureDialog = true;
            this.$nextTick(() => {
                this.$refs.carousel.setActiveItem(this.nowIndex);
            });
        },
        prevImage() {
            this.$refs.carousel.prev();
        },
        nextImage() {
            this.$refs.carousel.next();
        },
        next() {
            if (this.nowIndex < this.imgList.length) {
                this.nowIndex++;
            } else {
                this.transitioning = false;
                this.nowIndex = 1;
            }
            this.applyTransition();
        },
        prev() {
            if (this.nowIndex > 0) {
                this.nowIndex--;
            } else {
                this.transitioning = false;
                this.nowIndex = this.imgList.length - 1;
            }
            this.applyTransition();
        },
        applyTransition() {
            this.transitioning = true;
            setTimeout(() => {
                if (this.nowIndex === this.imgList.length) {
                    this.transitioning = false;
                    this.nowIndex = 0;
                } else if (this.nowIndex === -1) {
                    this.transitioning = false;
                    this.nowIndex = this.imgList.length - 5;
                }
            }, 500);
        },
        carouselChange(index) {
            this.currentIndex = index
        },
        showImage(index) {
            this.currentIndex = index;
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.$refs.fullsizeCarousel.setActiveItem(this.currentIndex);
            })
        },
        async getDetail() {
            const response = await post("/staticMoban/findMobanById", { id: this.id })
            this.mobanDetail = response.data.data
            this.imgList = this.mobanDetail.imgList.split(",")
            this.skillLableList = this.mobanDetail.skillLableName.split(",")
        },
        async getTuijian() {
            const response = await post("/staticMoban/findListByCategoryIds", { id: this.id })
            this.relatedList = response.data.data
        },
    },
    computed: {
        skillLableTags() {
            const shuffledTypes = this.shuffleArray(this.tagTypes.slice());
            return this.skillLableList.map((text, index) => ({
                text,
                type: shuffledTypes[index % shuffledTypes.length],
            }));
        },
        randomTagType() {
            const randomIndex = Math.floor(Math.random() * this.tagTypes.length);
            return this.tagTypes[randomIndex];
        },
        visibleImages() {
            // 在末尾附加前5张图片以实现无缝轮询
            return [...this.imgList, ...this.imgList.slice(0, 5)];
        },
        carouselStyle() {
            const offset = this.nowIndex * -(19.2 + 1); // 每张图片宽度为20%，外加2%的间隔
            return {
                transform: `translateX(${offset}%)`,
                transition: this.transitioning ? 'transform 0.5s ease' : 'none',
            };
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            // 检查是否有上一个路由
            vm.canGoBack = !!from.name;
        });
    },
    created() {
        if (this.$route.query.id) {
            this.id = this.$route.query.id
        }
        this.getDetail()
        this.getTuijian()
    },

    mounted() {

    },
}
</script>
<style lang="less" scoped>
* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

.container {

    .diannao {
        padding-top: 20px;
        width: 100%;
        background: #F6F6F6;

        .customDialog {
            width: 80% !important;
            margin: 0 auto;
        }

        video {
            width: 80%;
            height: 300px;
            object-fit: cover;
            display: block;
            margin: 20px auto;
        }

        ::v-deep .el-carousel__item h3 {
            text-align: center;
            margin-bottom: 20px;
            color: black;
        }

        ::v-deep .el-carousel__item img {
            width: 400px !important;
            height: 450px !important;
            display: block !important;
            margin: 0 auto !important;
            object-fit: cover !important;
        }

        ::v-deep .el-dialog__body {
            padding-top: 0px;
        }

        .carousel-container {
            position: relative;
            width: 100%;
            /* 容器宽度100% */
            overflow: hidden;
        }

        .carousel {
            display: flex;
            transition: transform 0.5s ease;
            height: 100%;
        }

        .carousel-item {
            min-width: 19.2%;
            margin-right: 1%;
            /* 每张图片右侧间隔2% */
            box-sizing: border-box;
            position: relative;
        }

        .carousel-item span {
            display: block;
            position: absolute;
            bottom: -20px;
            z-index: 9999;
        }

        .carousel-item img {
            width: 100%;
            height: 100%;
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        }

        .nav-button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: #007AFF;
            padding: 5px;
            color: white;
            border: none;
            cursor: pointer;
            border-radius: 5px;
        }

        .prev-button {
            left: 0;
        }

        .next-button {
            right: 0;
        }

        .breadcrumb {
            width: 75%;
            margin: 0 auto;
            margin-bottom: 30px;

            a {
                text-decoration: none;
                color: black;
                font-size: 16px;
            }

            a.second {
                color: #007aff;
            }
        }

        h3.title {
            border-left: 8px solid #007AFF;
            // background: #007aff;
            // color: white;
            width: 75%;
            margin: 0 auto;
            padding-left: 15px;
            margin-bottom: 20px;
            color: rgba(0, 0, 0, 0.7);

            // padding: 10px
        }

        .moban {
            width: 75%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            padding: 20px;
            height: 400px;
            background: white;
            margin-bottom: 20px;

            .left {
                flex-basis: 25%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .right {
                flex-basis: 65%;
                height: 100%;

                .price {
                    background: #F9F9F9;
                    padding-left: 20px;
                    margin: 30px 0px;
                    font-size: 18px;
                    width: 80%;

                    .s2 {
                        color: #D7263D;
                        line-height: 60px;
                        font-weight: bold;
                        display: inline-block;
                        margin-left: 20px;
                        font-size: 20px;
                    }
                }

                .info {
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 20px;
                    width: 90%;
                    margin-bottom: 35px;

                    .item {
                        flex-basis: 40%;
                        font-size: 15px;

                        .s1 {
                            color: #7d879c;
                            float: left
                        }

                        .s2 {
                            display: inline-block;
                            color: rgb(75, 86, 107);
                            margin-left: 20px;
                        }
                    }
                }

                .info:last-of-type {
                    margin-bottom: 0px;
                }

                .btns {
                    margin-top: 35px;

                    button {
                        background: #67C23A;
                        border: none;
                        color: white;
                        width: 100px;
                        height: 35px;
                        margin-right: 20px;
                        cursor: pointer;
                    }
                }
            }
        }

        .banquan {
            width: 75%;
            margin: 0 auto;
            padding: 20px;
            background: white;
            margin-bottom: 20px;

            p {
                line-height: 40px;
            }
        }

        .picture {
            width: 75%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            padding: 20px;
            height: 320px;
            background: white;
            margin-bottom: 20px;

            .imgs {
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                img {
                    height: 280px;
                    width: 19%;
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
                }
            }
        }

        .other {
            width: 75%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            padding: 20px;

            background: white;
            margin-bottom: 20px;

            .item {
                flex-basis: 19%;
                cursor: pointer;

                img {
                    display: block;
                    width: 100%;
                    height: 280px;
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
                }

                h3 {
                    cursor: pointer;
                    font-size: 16px;
                    text-align: center;
                    margin: 20px 0px;
                    color: black;
                    font-weight: bold;

                }
            }
        }

        .footer {
            height: 100px;
            line-height: 100px;
            background: #007AFF;
            color: white;
            text-align: center;
            font-size: 20px;
            letter-spacing: 3px;
            font-weight: bold;
        }
    }
}



.shouji {
    display: none;
}

/* 手机端样式 */
@media screen and (max-width: 768px) {


    .container {
        .diannao {
            display: none;
        }

        .shouji {
            display: block;

            .el-tag {
                margin-left: 5px;
                padding: 0px 3px;
            }

            .pageNumber {
                text-align: center;
                margin: 20px 0px;
            }

            h3.title {
                border-left: 5px solid #007aff;
                padding-left: 10px;
                font-size: 13px;
            }

            .top {
                width: 100%;
                height: 6vh;
                background: #007aff;
                color: white;
                margin-bottom: 10px;
                position: relative;

                img {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 25%;
                    left: 5%;
                }

                h2 {
                    text-align: center;
                    line-height: 6vh;
                    font-size: 18px;
                    vertical-align: middle;
                }
            }

            .lunbo {
                width: 100%;
                height: 33vh;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .jieshao {
                padding: 20px 10px;

                .items {
                    width: 90%;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin-top: 20px;

                    .item {
                        flex-basis: 50%;
                        color: #050505;
                        font-size: 15px;
                        margin-bottom: 10px;

                        .s1 {
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.8);

                        }

                        .s2 {
                            display: inline-block;
                            margin-left: 15px;
                        }
                    }

                    .item2 {
                        flex-basis: 100%;
                        margin-top: 10px;
                    }
                }
            }

            .shipin {
                padding: 0px 10px;

                video {
                    width: 100%;
                    height: 220px;
                    object-fit: cover;
                    margin: 20px 0px;
                }
            }







            .shuoming {
                padding: 0px 10px;
                margin-bottom: 10px;

                p {
                    margin-top: 10px;
                    font-size: 14px;
                    line-height: 30px;
                }
            }

            /* Custom styles for carousel arrows */
            ::v-deep .el-carousel__arrow--left {
                background-color: rgba(0, 0, 0, 0.5) !important;
                color: #ffffff !important;
                border-radius: 50%;
            }

            ::v-deep .el-carousel__arrow--right {
                background-color: rgba(0, 0, 0, 0.5) !important;
                color: #ffffff !important;
                border-radius: 50%;
            }

            ::v-deep .el-carousel__container {
                height: 380px;
            }

            .el-carousel__item img {
                width: 100%;
                height: auto;
                display: block;
            }

            .full-image {
                width: 100%;
                height: auto;
                display: block;
            }

            .large-dialog .el-dialog__body {
                padding: 0;
                height: 80vh;
                /* Adjust the height as needed */
            }

            ::v-deep .el-dialog__body {
                padding: 20px 20px;
            }

            .large-dialog {
                max-width: 90%;
                width: 90%;
                top: 10px;
                padding-bottom: 0;
            }

            ::v-deep .el-dialog {
                margin-top: 16vh !important;
            }

            .el-dialog__wrapper {
                align-items: center;
                justify-content: center;
            }

            .el-carousel {
                height: 100%;
            }

            .el-carousel__item {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

        }



    }

}
</style>
