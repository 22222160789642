<template>
  <div class="container">

    <div class="diannao">
      <div class="breadcrumb">
        <a href="">首页 > </a> <a href="" class="second">模版中心</a>
      </div>
      <div class="tiaojian">
        <div class="item">
          <div class="left">
            <span>主题:</span>
          </div>
          <div class="right">
            <span :class="{ 'active': currentCategoryId === item.id }" v-for="(item, index) in categoryList"
              @click="categoryClick(item.id)">{{ item.name }}</span>
          </div>

        </div>
        <div class="item item2">
          <div class="left">
            <span>页数:</span>
          </div>
          <div class="right">
            <span :class="{ 'active': pageIndex === index }" v-for="(item, index) in pageObjList"
              @click="pageClick(index)">{{ item.lable }}</span>

          </div>


        </div>
        <div class="item item2">
          <div class="left">
            <span>技术:</span>
          </div>
          <div class="right">
            <!-- <span class="active">全部</span>
            <span>html+css</span>
            <span>vue</span>
            <span>bootstrap</span>
            <span>javascript</span>
            <span>表格布局</span> -->
            <span :class="{ 'active': skillIdList.includes(item.id) }" v-for="(item, index) in lableList"
              @click="skillClick(item.id)">{{ item.name }}</span>

            <!-- <div class="items">
              <div :class="{ 'item': true, 'active': skillIdList.includes(item.id) }" v-for="(item, index) in lableList"
                @click="skillClick(item.id)">{{ item.name }}
              </div>
            </div> -->


          </div>
        </div>

        <div class="item item2" style="height: 100px;">
          <div class="left">
            <span>关键词:</span>
          </div>
          <div class="right">
            <div class="keyword"> <input v-model="searchForm.mobanName" placeholder="请输入要搜索的关键词" type="text">
              <button @click="handleSearch">搜索</button>
            </div>
          </div>


        </div>


      </div>
      <div v-if="mobanList.length <= 0" class="empty">
        <img src="../assets/empty.png" alt="">
        <h3>暂未查找到对应模版</h3>
      </div>
      <div v-else class="mobanList">
        <div class="item" @click="viewDetail2(item.id)" v-for="item in mobanList">
          <img class="cover" :src="item.coverImg" alt="">
          <div class="mobaninfo">
            <h3 class="title3">{{ item.title }}/{{ item.categoryName }}主题</h3>
            <div class="skill">
              <img src="../assets/biaoshi.png" alt="">
              <span>编号: {{ item.bizNo }}</span>
              <span>页数: {{ item.pageNumber }}</span>
            </div>
            <div class="skill">
              <img src="../assets/biaoshi.png" alt="">
              <span>技术:{{ item.skillLableName }} </span>
            </div>
            <div class="price">
              <span class="s1">¥</span><span class="s2">{{ item.price }}</span>
              <button>查看</button>
            </div>
          </div>
        </div>
      </div>

      <div class="page" v-if="mobanList.length > 0">
        <el-pagination background layout="prev, pager, next" :page-size="pageForm.pageSize" :total="pageForm.total"
          @current-change="handleCurrentChange">
        </el-pagination>
      </div>

      <div class="footer">
        袋鼠网页设计-成品源码站
      </div>
    </div>

    <div class="shouji">
      <div class="top">
        <div class="search">
          <img v-if="canGoBack" @click="back" class="back" src="../assets/back.png" alt="">
          <img class="search" src="../assets/search.png" alt="">
          <input v-model="searchForm.mobanName" @focus="searchFocus" type="text" placeholder="请输入关键词">
          <button>搜索</button>
        </div>
      </div>
      <div class="type">
        <div class="item">
          <span class="active">综合</span>
        </div>
        <div class="item" id="shaixuan" @click=showshaixuan>
          <span>筛选</span>
          <img src="../assets/shaixuan.png" alt="">
        </div>
      </div>
      <transition name="fade">
        <div class="shaixuan" v-if="shaixuanshow">
          <img @click="close" src="../assets/close.png" alt="">
          <div class="sitem">
            <h3>主题</h3>
            <div class="items">
              <div :class="{ 'item': true, 'active': currentCategoryId === item.id }"
                v-for="(item, index) in categoryList" @click="categoryClick(item.id)">{{ item.name }}</div>
            </div>
          </div>
          <div class="sitem">
            <h3>页数</h3>
            <div class="items">
              <div :class="{ 'item': true, 'active': pageIndex === index }" v-for="(item, index) in pageObjList"
                @click="pageClick(index)">{{ item.lable }}
              </div>
            </div>
          </div>
          <!--    <div class="sitem">
            <h3>技术</h3>
            <div class="items">
              <div :class="{ 'item': true, 'active': skillIdList.includes(item.id) }" v-for="(item, index) in lableList"
                @click="skillClick(item.id)">{{ item.name }}
              </div>
            </div>
          </div> -->
        </div>
      </transition>
      <div :style="{ height: dynamicHeight + 'vh' }" class="mobanList" @scroll="scroll">
        <div class="empty" v-if="emptyShow">
          <img src="../assets/empty.png" alt="">
          <p>暂无该类型模版</p>
          <p class="p2">可联系客服定制</p>
        </div>
        <!-- @scroll="scroll" -->
        <div class="container-water-fall">
          <!-- 第一列 -->
          <div class="column">
            <div v-for="(item, index) in column1" :key="index" class="cell-item" @click="viewDetail(item.id)">
              <img :src="item.coverImg" alt="加载失败、刷新重试">
              <div class="item-body">
                <h3 class="title">{{ item.title }}/{{ item.categoryName }}主题</h3>
                <div class="skill">
                  <img src="../assets/biaoshi.png" alt="">
                  <span>编号: {{ item.bizNo }}</span>
                  <span>页数: {{ item.pageNumber }}</span>
                </div>
                <div class="skill">
                  <img src="../assets/biaoshi.png" alt="">
                  <span>技术:{{ item.skillLableName }} </span>
                </div>
                <div class="price">
                  <span class="s1">¥</span><span class="s2">{{ item.price }}</span>
                  <button>查看</button>
                </div>

              </div>
            </div>
          </div>
          <!-- 第二列 -->
          <div class="column">
            <div v-for="(item, index) in column2" :key="index" class="cell-item" @click="viewDetail(item.id)">
              <img :src="item.coverImg" alt="加载失败、刷新重试" :style="index === 0 ? { height: '250px' } : {}">
              <div class="item-body">
                <h3 class="title">{{ item.title }}/{{ item.categoryName }}主题</h3>
                <div class="skill">
                  <img src="../assets/biaoshi.png" alt="">
                  <span>编号: {{ item.bizNo }}</span>
                  <span>页数: {{ item.pageNumber }}</span>
                </div>
                <div class="skill">
                  <img src="../assets/biaoshi.png" alt="">
                  <span>技术:{{ item.skillLableName }} </span>
                </div>
                <div class="price">
                  <span class="s1">¥</span><span class="s2">{{ item.price }}</span>
                  <button>查看</button>
                </div>

              </div>
            </div>
          </div>

        </div>
        <div v-if="loading" class="loading-animation">
          <div class="loading-dots">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>

        </div>
        <div v-if="noMoreData" class="noMoreData">暂无最新数据</div>

      </div>



      <v-tour name="myTour" :steps="steps" :options="myOptions" :callbacks="myCallbacks"></v-tour>
    </div>


  </div>
</template>

<script>
import vueWaterfallEasy from 'vue-waterfall-easy'
import { post } from "@/util/request";
import { getCategoryList } from "@/util/api";
import loading from '@/views/loading.vue'
import debounce from 'lodash/debounce';

export default {
  components: {
    vueWaterfallEasy,
    loading
  },
  data() {
    return {
      noMoreData: false,
      column1: [],  // 第一列的图片数据
      column2: [],
      canGoBack: false,
      isMobile: false,
      emptyShow: false,
      waterfallShow: true,
      currentCategoryId: '',
      pageIndex: 0,
      categoryList: [],
      hasWaterfallCustom: false,
      data: [],
      mobanList: [],
      searchForm: {
        categoryId: '',
        pageNumberMin: 0,
        pageNumberMax: 0,
        mobanName: '',
        skillLable: ''
      },
      pageObjList: [
        { lable: '全部', min: 0, max: 0 },
        { lable: '1页', min: 1, max: 1 },
        { lable: '2-5页', min: 2, max: 5 },
        { lable: '5-10页', min: 5, max: 10 },
        { lable: '10页以上', min: 10, max: 0 }
      ],

      pageForm: {
        page: 1,
        pageSize: 10,
        total: 0,
        keyword: "",
      },
      shaixuanshow: false,
      lableList: [],
      skillIdList: [0],
      steps: [
        {
          target: '#shaixuan',  // We're using document.querySelector() under the hood
          header: {
            title: '点击筛选按钮',
          },
          content: `根据网页主题、页数等条件筛选`,
          // params: {
          //     placement: 'right-start' 
          //   }
        }

      ],
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: '跳过',
          buttonPrevious: '上一步',
          buttonNext: '下一步',
          buttonStop: '了解'
        }
      },
      col: 2,
      loading: false,
      isRefreshing: false,
      myCallbacks: {
        onStop: this.myCustomStopCallback
      },
      isJintaiTour: true
    };
  },
  methods: {
    myCustomStopCallback() {
      localStorage.setItem("jintaiTour", false)
    },

    skillClick(id) {
      if (id == 0) {
        this.skillIdList = [0]
      } else {
        this.skillIdList = this.skillIdList.filter(id => id !== 0);
        const index = this.skillIdList.indexOf(id);
        if (index === -1) {
          // 如果条件不在数组中，则添加
          this.skillIdList.push(id);
        } else {
          // 如果条件已经在数组中，则移除
          this.skillIdList.splice(index, 1);
        }
      }
      if (this.skillIdList.length == 0) {
        this.skillIdList.push(0)
      }

      var tempList = this.skillIdList.filter(id => id !== 0);
      if (tempList.length > 0) {
        this.searchForm.skillLable = tempList.join(",")
      } else {
        this.searchForm.skillLable = ''
      }
      if (this.isMobile) {
        this.loadTableData('search')
      } else {
        this.loadNextPageData()
      }

    },
    close() {
      this.shaixuanshow = false
    },
    back() {
      this.$router.go(-1)
    },
    handleSearch() {
      this.pageForm['page'] = 1
      this.loadNextPageData()
    },
    checkDeviceType() {
      this.isMobile = window.innerWidth <= 768; // 假设小于等于768px的是手机端
    },


    viewDetail(id) {
      this.$router.push({ name: 'jintaidetail', query: { id: id } })
    },
    viewDetail2(id) {
      this.$router.push({ name: 'jintaidetail', query: { id: id } })
    },
    categoryClick(categoryId) {
      this.currentCategoryId = categoryId
      this.searchForm.categoryId = categoryId
      this.pageForm['page'] = 1
      if (this.isMobile) {
        this.loadTableData('search')
      } else {
        this.loadNextPageData()
      }
    },

    pageClick(index) {
      this.pageIndex = index
      this.searchForm.pageNumberMin = this.pageObjList[index].min
      this.searchForm.pageNumberMax = this.pageObjList[index].max
      this.pageForm['page'] = 1
      if (this.isMobile) {
        this.loadTableData('search')
      } else {
        this.loadNextPageData()
      }

    },


    searchFocus() {
      this.$router.push('/search')
    },
    showshaixuan() {
      this.shaixuanshow = !this.shaixuanshow
    },

    handleCurrentChange(currentPage) {
      this.pageForm.page = currentPage;
      this.loadNextPageData();
    },


    async loadNextPageData() {
      this.pageForm.pageSize = 15
      var params = { ...this.pageForm, ...this.searchForm }
      const response = await post("/staticMoban/getMobilePage", params)
      this.pageForm.total = response.data.data.total;
      this.mobanList = response.data.data.records;
    },


    async getLableList() {
      const response = await post("/lable/findAllList")
      this.lableList = response.data.data;
      this.lableList.unshift({ id: 0, name: '全部' });
    },

    scroll: function (event) {
      const container = event.target; // 获取滚动容器
      const scrollPosition = (container.scrollTop + container.clientHeight); // 滚动条位置 + 可视
      const bottomPosition = container.scrollHeight - 1; // 滚动容器的总高度
      // 判断是否滚动到底部
      let isAtBottom = scrollPosition >= bottomPosition;
      //到达底部，开始更新数据

      if (isAtBottom) {

        this.getData()
      }
    },


    async loadTableData(type) {
      if (type == 'search') {
        this.mobanList = []
        this.pageForm['page'] = 1
      }

      var params = { ...this.pageForm, ...this.searchForm }
      const response = await post("/staticMoban/getMobilePage", params)
      this.loading = false;
      this.mobanList = [...this.mobanList, ...response.data.data.records]
      this.column1 = []
      this.column2 = []
      this.mobanList.forEach((item, index) => {
        if (index % 2 === 0) {

          this.column1.push(item); // 偶数索引放到第一列
        } else {

          this.column2.push(item); // 奇数索引放到第二列
        }
      });
      if (response.data.data.records.length <= 0 && type == 'search') {

        this.emptyShow = true
        this.noMoreData = false

      }

      if (response.data.data.records.length > 0 && type == 'search') {
        this.emptyShow = false
      }
      this.pageForm.total = response.data.data.total

    },
    getData() {

      if (this.mobanList.length >= this.pageForm.total) {
        this.loading = false
        this.noMoreData = true
      } else {
        this.pageForm.page++
        this.loadTableData()
        this.loading = true
      }

    },

  },
  computed: {
    dynamicHeight: {
      get() {
        return this.shaixuanshow ? '45' : '77';
      },
      set(value) {
        this.dynamicHeight = value;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 检查是否有上一个路由
      vm.canGoBack = !!from.name;
    });
  },
  async created() {
    this.checkDeviceType();
    this.getLableList()
    window.addEventListener('resize', this.checkDeviceType);
    if (this.$route.query.categoryId) {
      this.searchForm.categoryId = this.$route.query.categoryId
      this.currentCategoryId = this.$route.query.categoryId
    }
    if (this.$route.query.mobanName) {
      this.searchForm.mobanName = this.$route.query.mobanName
      this.currentCategoryId = this.$route.query.categoryId
    }

    this.categoryList = await getCategoryList()
    this.categoryList.unshift({ id: '', name: '全部' });

    if (this.isMobile) {
      if (this.searchForm.mobanName) {
        this.loadTableData('search');
      } else {
        this.loadTableData();
      }
    } else {
      this.loadNextPageData()
    }



  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkDeviceType);
  },
  mounted: function () {
    window.scrollTo(0, 0);
    let flag = localStorage.getItem("jintaiTour")

    if (flag != null) {
      this.isJintaiTour = flag === "true"
    }
    if (this.isJintaiTour) {
      this.$tours['myTour'].start()
    }

  }

};
</script>
<style lang="less" scoped>
* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.shouji {
  display: none;
}

.container {

  background: #F6F6F6
}

::v-deep .vue-tour__popover {
  margin-right: 20px;
  /* 增加右侧外边距，控制与屏幕右边的间隙 */
}

::v-deep .v-step__header {
  background: #3A86FF !important;
}

::v-deep .v-step {
  background: #3A86FF !important;
  font-size: 14px;
  inset: 0px auto auto -15px !important;
}

::v-deep .v-step__arrow::before {
  background: #3A86FF !important;
}

.diannao {
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;

  .empty {
    height: 450px;

    img {
      width: 200px;
      height: 170px;
      display: block;
      margin: 0 auto;
      margin-top: 200px;
    }

    h3 {
      text-align: center;
      color: #333333;
      margin-top: 30px;
    }
  }

  .breadcrumb {
    width: 80%;
    margin: 0 auto;

    a {
      text-decoration: none;
      color: black;
      font-size: 16px;
    }

    a.second {
      color: #007aff;
    }
  }

  .tiaojian {

    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    background: white;

    .item {
      width: 100%;
      height: 110px;

      display: flex;
      padding: 20px 20px;

      div.left {
        width: 5%;
      }

      div.right {
        width: 95%;
        display: flex;
        flex-wrap: wrap;

        span {
          cursor: pointer;
          display: inline-block;
          width: 80px;
          height: 30px;
          font-size: 14px !important;
          border: 1px solid #ddd;
          text-align: center;
          margin-right: 40px;
          text-align: center;
          margin-bottom: 15px;
          padding: 5px;
          font-size: 12px;
          border-radius: 5px
        }

        span.active {
          background: #007aff;
          color: white;
          border: none;
        }

        .keyword {
          display: flex;
          width: 55%;
          margin-top: -5px;

          input {
            flex-basis: 790px;
            display: block;
            height: 50px;
            float: left;
            border: 2px solid #007aff;
            outline: none;
            /* 去掉聚焦时的轮廓 */
            padding-left: 15px;
          }

          input::placeholder {
            font-size: 16px;
            padding-left: 15px;
            letter-spacing: 3px;
          }

          button {
            display: inline-block;
            flex-basis: 110px;
            height: 50px;
            background: #007AFF;
            border: none;
            color: white;
            text-align: center;
            line-height: 50px;
            font-size: 16px;
            cursor: pointer;
          }
        }

      }


    }

    .item2 {
      height: 70px;
    }
  }

  .mobanList {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
    min-height: 850px;
    gap: 1%;

    .item {
      height: 422px;
      flex-basis: 19.2%;
      margin-bottom: 30px;
      box-shadow: 0 .125rem .3rem -.0625rem rgba(0, 0, 0, .03), 0 .275rem .75rem -.0625rem rgba(0, 0, 0, .06) !important;
      border-radius: 10px;

      h3.title2 {
        margin: 10px 0px;
        text-align: center;
      }

      img.cover {
        width: 100%;
        height: 300px;
        border-radius: 10px;
      }

      .mobaninfo {
        padding-bottom: 15px;
        padding-top: 10px;
        background: white;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        background: white;

        h3.title3 {
          font-size: 15px;
          font-weight: bold;
        }

        .skill {
          margin-top: 5px;

          img {
            width: 10px;
            height: 10px;
          }

          span {
            font-size: 14px;
            vertical-align: middle;
            display: inline-block;
            margin-left: 5px;

          }
        }

        .price {
          color: #E63946;
          padding-right: 5px;
          margin-top: 5px;

          .s1 {
            font-size: 13px;
            font-weight: bold;
            margin-right: 3px;
          }

          .s2 {
            font-size: 18px;
            font-weight: bold;
          }

          button {
            background: #D81E06;
            height: 20px;
            width: 60px;
            text-align: center;
            color: white;
            float: right;
            margin-top: 5px;
            border-radius: 10px;
            border: none;
            font-weight: 500;
          }

        }
      }
    }

  }

  .page {
    width: 80%;
    margin: 0 auto;
    height: 80px;

    ::v-deep .el-pagination {
      float: right;
      margin-top: 20px
    }

    ::v-deep .el-pagination.is-background .el-pager li {
      background-color: white;
    }

    ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #409EFF;
      color: #FFF;
    }
  }

  .footer {
    height: 100px;
    line-height: 100px;
    background: #007AFF;
    color: white;
    text-align: center;
    font-size: 20px;
    letter-spacing: 3px;
    font-weight: bold;
  }
}

/* 手机端样式 */

@media screen and (max-width: 768px) {



  /* 内容区域 */
  .container {
    .diannao {
      display: none;
    }

    /* 加载动画样式 */
    .loading-animation {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      /* 垂直排列点动画和文字 */
      padding: 20px 0;
      height: 7vh;
      background: #f2f4f5;
      margin-bottom: 10px;
    }

    .loading-dots {
      display: flex;
      gap: 12px;
      margin-bottom: 10px;
    }

    .dot {
      width: 10px;
      height: 10px;
      background-color: #007AFF;
      border-radius: 50%;
      opacity: 1;
      animation: dotGrow 1s ease-in-out infinite;
    }

    /* 每个圆点有不同的动画延迟，确保它们依次变大 */
    .dot:nth-child(1) {
      animation-delay: 0s;
    }

    .dot:nth-child(2) {
      animation-delay: 0.4s;
    }

    .dot:nth-child(3) {
      animation-delay: 0.6s;
    }

    /* 定义圆点动画 */
    @keyframes dotGrow {

      0%,
      100% {
        opacity: 0;
        transform: scale(0);
        /* 圆点初始很小且不可见 */
      }

      50% {
        opacity: 1;
        transform: scale(1.5);
        /* 圆点变大 */
      }
    }

    .loading-text {
      color: #666;
      font-size: 14px;
      font-weight: bold;
    }

    .shouji {
      display: block;

      .container-water-fall {
        width: 100vw;
        box-sizing: border-box;
        background: #f2f4f5;
        padding: 0px 10px;
        overflow-y: auto;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        padding: 10px;

        .column {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        .cell-item {
          // width: 49%;
          // margin-bottom: 10px;

          img {
            // border-radius: 12px 12px 0 0;
            width: 100%;
            height: 235px;
            display: block;
          }
        }

        .item-body {
          padding-left: 10px;
          padding-top: 10px;
          padding-bottom: 15px;
          background: white;
          text-align: left;

          h3.title {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.8);

            font-weight: bold;

          }

          .skill {
            margin-top: 5px;

            img {
              width: 10px !important;
              height: 10px !important;
              display: inline-block !important;
            }

            span {
              font-size: 12px;
              vertical-align: middle;
              display: inline-block;
              margin-left: 5px;

            }
          }


          .price {
            color: #E63946;
            padding-right: 5px;
            margin-top: 5px;

            .s1 {
              font-size: 13px;
              font-weight: bold;
              margin-right: 3px;
            }

            .s2 {
              font-size: 18px;
              font-weight: bold;
            }

            button {
              background: #D81E06;
              height: 20px;
              width: 60px;
              text-align: center;
              color: white;
              float: right;
              margin-top: 5px;
              border-radius: 10px;
              border: none;
              font-weight: 500;
              line-height: 20px;
            }

          }
        }

        h4 {
          padding-top: 56px;
          padding-bottom: 28px;
          font-family: PingFangSC-Medium;
          font-size: 36px;
          color: #000000;
          letter-spacing: 1px;
          text-align: justify;
        }

        button {
          background-color: #ff0;
          color: #24292e;
          border: 1px solid rgba(27, 31, 35, 0.2);
          border-radius: 0.25em;
          width: 100px;
          line-height: 26px;
          font-size: 13px;
          margin: 4px 0;
          margin-right: 4px;
          cursor: pointer;
          outline: none;

          &.blue-light {
            background: #27fbc2;
          }
        }

        button:hover {
          background-image: linear-gradient(-180deg, #fafbfc, #ccc 90%);
        }

        // .cell-item {
        //   width: 100%;
        //   // margin-bottom: 18px;
        //   background: #ffffff;

        //   overflow: hidden;
        //   box-sizing: border-box;
        //   margin-bottom: 10px;

        //   img {
        //     // border-radius: 12px 12px 0 0;
        //     width: 100%;
        //     height: 235px;
        //     display: block;
        //   }


        // }
      }

      .top {
        display: flex;
        width: 100%;
        height: 9.5vh;
        background-image: url('../assets/bg.png');
        display: block;
        box-shadow: none;


        .search {
          padding-top: 1vh;
          position: relative;

          img.back {
            position: absolute;
            top: 30%;
            left: 2%;
            width: 25px;
            height: 25px;
          }

          img.search {
            position: absolute;
            top: 38%;
            left: 16%;
            width: 18px;
            height: 25px;
          }

          input {
            width: 75%;
            height: 40px;
            margin: 0 auto;
            border-radius: 20px;
            display: block;
            margin-top: 10px;
            border: none;
            background: white;
            padding: 10px;
            padding-left: 50px;
            letter-spacing: 2px;
            font-size: 16px;
          }

          button {
            width: 80px;
            height: 60%;
            background: #007aff;
            border: none;
            position: absolute;
            right: 13%;
            top: 35%;
            color: white;
            border-radius: 20px;
            font-size: 15px;
            font-weight: bold;
          }
        }

      }

      /* 筛选页面 */
      .shaixuan {
        width: 100%;
        top: 14.5vh;
        height: 40vh;
        background: white;
        border-top: 0.5px solid #ededed;
        z-index: 999;
        padding: 0px 20px;
        padding-bottom: 20px;
        padding-top: 5px;
        border-bottom: 0.5px solid #ededed;
        overflow-y: scroll;

        img {
          width: 18px;
          height: 18px;
          position: absolute;
          right: 15px;
          margin-top: 15px;
        }

        .sitem {
          margin-bottom: 10px;

          h3 {
            color: #a6a6b0;
            font-weight: normal;
            font-size: 15px;
            margin-top: 15px;
          }

          .items {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-top: -2px;

            .active {
              background: #007aff !important;
              color: white;
            }

            .item {
              flex-basis: 19%;
              text-align: center;
              background: #F9FAFC;
              margin-top: 5px;
              padding: 5px;
              margin-right: 3.5px;
              font-size: 12px;
              border-radius: 5px;
              margin-top: 15px;
            }
          }
        }
      }

      /* 类型/综合和筛选 */
      .type {
        width: 100%;
        height: 5.5vh;
        display: flex;
        background: white;


        .item {
          flex-basis: 50%;
          line-height: 5.5vh;
          text-align: center;

          img {
            width: 18px;
            height: 18px;
            vertical-align: middle;
            display: inline-block;
            margin-left: 5px;
          }

          span {
            color: #a6a6b0
          }

          span.active {
            color: #007aff;
            font-weight: bold;
          }
        }
      }

      /* 模版列表展示内容区域*/
      .mobanList {
        width: 100%;
        background: #F2F4F5;

        font-family: "微软雅黑";
        position: relative;
        overflow-y: auto;

        .empty {
          width: 100%;
          height: 100%;

          img {
            width: 150px;
            height: 120px;
            display: block;
            margin: 0 auto;
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);

          }

          p {
            text-align: center;
            margin-top: 20px;
            color: #a6a6b0;
            letter-spacing: 2px;
            position: absolute;
            top: 60%;

            font-size: 15px;
            width: 100%;
          }

          p.p2 {
            color: #D81E06;
            top: 70%;
            font-weight: bold;
          }
        }

        .mobaninfo {
          padding-left: 10px;
          padding-top: 10px;
          padding-bottom: 10px;
          background: white;

          h3.title {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.8);

            font-weight: bold;

          }

          .skill {
            margin-top: 5px;

            img {
              width: 10px;
              height: 10px;
            }

            span {
              font-size: 12px;
              vertical-align: middle;
              display: inline-block;
              margin-left: 5px;

            }
          }


          .price {
            color: #E63946;
            padding-right: 5px;
            margin-top: 5px;

            .s1 {
              font-size: 13px;
              font-weight: bold;
              margin-right: 3px;
            }

            .s2 {
              font-size: 18px;
              font-weight: bold;
            }

            button {
              background: #D81E06;
              height: 20px;
              width: 60px;
              text-align: center;
              color: white;
              float: right;
              margin-top: 5px;
              border-radius: 10px;
              border: none;
              font-weight: 500;
            }

          }
        }

      }
    }

  }

  /* 筛选框显示隐藏动画 */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.8s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  /* 下拉进度条动画 */
  @keyframes bounce {

    0%,
    80%,
    100% {
      transform: scale(0);
    }

    40% {
      transform: scale(1);
    }
  }

  /* 下拉加载进度条 */
  .loader {
    display: flex;
    justify-content: space-between;
    width: 60px;
    margin-top: 30px;

    .dot {
      width: 15px;
      height: 15px;
      background-color: #007aff;
      border-radius: 50%;
      animation: bounce 1.4s infinite ease-in-out both;
    }

    .dot:nth-child(1) {
      animation-delay: -0.32s;
    }

    .dot:nth-child(2) {
      animation-delay: -0.16s;
    }
  }

  .noMoreData {
    text-align: center;
    font-size: 13px;
    color: #666;
    line-height: 60px;
  }

  /* 瀑布流样式 */
  ::v-deep .vue-waterfall-easy-container .vue-waterfall-easy .over {
    padding: 20px;
  }

  .waterfallCustom {
    padding-bottom: 30px;
  }

  .waterfall-item {
    margin-bottom: 20px;
    /* 根据你的列数和间隙调整 */
  }
}
</style>
